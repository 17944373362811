import goals from "./goals";
import competence from "@hihub/vue-hihub-competences/src/store/competence";

/**
 * Vuex store accumulator
 *
 * Reexport to simplest use in main.js
 */

export default [
  {
    name: "goals",
    module: goals
  },
  {
    name: "competence",
    module: competence
  }
];
