export default {
  subheader: {
    breadcrumbs: {
      display: false
    }
  },
  content: {
    width: "fluid",
  },
};
