/**
 * Aside menu config
 *
 * To more info about config of menu items go to knowledgebase, lms, review (in review you can see menu for Head of department)
 */
import store from "@/core/services/store";

export default {
  submenu: {
    main:{
      to: {
        name: "Main",
        params: {}
      },
      active: true,
      icon: "flaticon2-menu-2",
      text: {
        code: "menu.main",
        default: "Main"
      }
    },
    allGoals: {
      active: function() {
          const user = store.getters.currentUser;
          return user ? user.is_admin : false;
          //return true;
      },
      to: {
        name: "AllGoals",
        params: {}
      },
      b_icon: "clipboard-data",
      text: {
        code: "menu.all_goals",
        default: "All Goals"
      }
    },
    forAgreement: {
      to: {
        name: "GoalsForAgreement",
        params: {}
      },
      active: true,
      b_icon: "check2-square",
      text: {
        code: "menu.for_agreement",
        default: "GoalsForAgreement"
      }
    },
    headOfDep: {
      active: function(){
        const user = store.getters.currentUser;
        return user ? user.is_head : false;
        //return true;
      },
      b_icon: "people-fill",
      text: {
        code: "menu.head",
        default: "Head of department pages"
      },
      submenu: {
        usersOfDepartmentGoals: {
            active: true,
            to: {
                name: "SubordinatesGoals",
                params: {},
            },
            text: {
                code: "menu.users_of_department_goals",
                default: "Goals of subordinates"
            },
            b_icon: "file-earmark-person",
        },
      }
    }
    /*create: {
      to: {
        name: "GoalCreate",
        params: {}
      },
      active: true,
      icon: "flaticon2-plus",
      text: {
          code: "menu.create",
          default: "Goals create"
      }
    },*/
    /*myGoals: {
      to: {
        name: "Main",
        params: {}
      },
      active: true,
      icon: "flaticon2-document",
      text: {
        code: "menu.my_goals",
        default: "My goals"
      }
    }*/
  }
};
