export default [
    {
        path: "/",
        alias: "/goals",
        redirect: { name: "Main" },
        name: "root",
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: "/",
                components: {
                    "title-actions": () => import("./components/ActionsButtons/CreateGoal.vue"),
                    default: () => import("./views/Main.vue")
                },
                name: "Main",
            },
            {
                path: "/goal/create",
                component: () => import("./views/GoalCreate.vue"),
                name: "GoalCreate",
            },
            {
                path: "/goal/view/:goal_id",
                components: {
                    "title-actions": () => import("./components/ActionsButtons/DeleteGoal.vue"),
                    default: () => import("./views/GoalView.vue"),
                },
                name: "GoalView",
                props: {default: true},
            },
            {
                path: "/goal/mygoals",
                component: () => import("./views/MyGoals.vue"),
                name: "MyGoals",
                props: true
            },
            {
                path: "/all_goals",
                components: {
                    "title-actions": () => import("./components/ActionsButtons/CreateGoal.vue"),
                    default: () => import("./views/AllGoals.vue")
                },
                name: "AllGoals",
            },
            {
                path: "/head/subordinates-list",
                component: () => import("./views/SubordinatesGoals.vue"),
                name: "SubordinatesGoals"
            },
            {
                path: "/for-agreement",
                component: () => import("./views/GoalsForAgreement.vue"),
                name: "GoalsForAgreement"
            }
        ]
    }
];
